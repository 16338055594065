body
  background-color: black
  
.Clock
  display: block
  color: white
  background-color: black
  position: absolute
  top: 54% !important
  left: 50% !important
  width : unset !important
  height : unset !important
  transform: translate(-50%, -50%)
  padding-left: 17px
  padding-right: 17px
  
// .Pic
//   position: absolute
//   top: 0
//   left: 0
//   width: 100%
//   height: 100%
//   cursor: grab
.rmwidget.widget-picture .Pic__close
  position: absolute
  top: 7px !important
  right: 7px !important
  left: unset !important
  width: 25px !important
  height: 25px !important
  cursor: pointer
.d-none
  display: none !important


a
  &.visited
    text-decoration: line-through
  &.current
    color: rgba(72, 171, 177, 1) !important

.draggable img
  pointer-events: none !important

// a.link-1
//   color: white